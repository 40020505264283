<template>
	<div>
		<div class="filter-container" style="background: #fff;padding: 10px;overflow:hidden;">

      <div class='filter-top'>
        <span>订单毛利预算统计规则：</span>
				<br>
        <span>1.根据订单支付时间，统计在相应时间段内支付成功的订单数据；
					<span style="margin:0 20px;">2.未支付或待成团的订单不会统计在该列表</span>
				</span>
      </div>

			<div class="filter-item">
				<label class="label">支付时间: </label>
				<el-date-picker v-model="startTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" type="datetime" placeholder="开始时间" :picker-options='maxOptions'>
				</el-date-picker>
				<span style="margin:0 10px;">~</span>
				<el-date-picker v-model="endTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"  default-time="23:59:59" type="datetime" placeholder="结束时间" :picker-options='minOptions'>
				</el-date-picker>
			</div>

			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input  v-model="keyWord" style='width:220px;' placeholder="订单编号" @keyup.enter.native="getList" ></el-input>
			</div>

			<div class="filter-item">
				<label class="label">列表排序: </label>
				<el-select v-model="SortType">
					<el-option :value="0" label="默认排序"></el-option>
					<el-option :value="1" label="订单毛利从高到低"></el-option>
					<el-option :value="2" label="订单毛利从低到高"></el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" style="width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:20px;width:90px;" @click="handleExport">导出</el-button>
			</div>

			<div class="gross-infos">
				<div>订单毛利总额：<span :style="{'color':Number(grossProfit.moneyAmount)>0?'#000':'#F56C56'}">{{grossProfit.moneyAmount}}</span></div>
				<div class="gross-detail">
					<span style="margin-right:100px">订单毛利:订单内各商品毛利预算之和</span>
					<template>
						<span class="gross-item">待发货订单：{{grossProfit.NotSendOrderCount}}（发出包裹个数：{{grossProfit.NotSendExpressCount}}）</span>
						<span class="gross-item">已发货订单：{{grossProfit.SendOrderCount}}（发出包裹个数：{{grossProfit.SendExpressCount}}）</span>
						<span class="gross-item">全部退款订单：{{grossProfit.RefundOrderCount}}（发出包裹个数：{{grossProfit.RefundExpressCount}}）</span>

					</template>
				</div>
			</div>
 <!-- height="555" -->
			<el-table :data="groupData" :span-method="arraySpanMethod" :header-row-style="headerStyle"  :row-class-name="setClass"  style="width: 100%;" v-loading="loading" :max-height='tableMaxHeight'>
					<el-table-column prop="" label="商品"   min-width="250px">
						<template slot-scope="scope">
							<div class="name-wraper" v-if='scope.row.type == 1'  :style="{background:scope.row.type == 1?'#f0f2f5':'transparents'}" >
								<div class="line">	
									<div class="single" style="width:222px">订单：<span style="color:#409EFF;cursor:pointer;" @click="lookOrderDetails(scope.row)">{{scope.row.OrderNo}}</span></div>
									<div class="single">订单类型：{{scope.row.OrderTypeValue}}</div>
									<div class="single">下单时间：{{scope.row.AddTime}}</div>
									<div class="single">支付时间：{{scope.row.PayTime}}</div>
									<div class="single">订单状态：{{scope.row.OrderStateValue}}</div>
									<div class="single">订单毛利：
										<span :style="{'color':Number(scope.row.OrderGrossProfitMoney)>0?'#606266':'#F56C56'}">
											{{scope.row.OrderGrossProfitMoney}}
										</span>
									</div>
								</div>
								<div  class="line">	
									<div class="single" style="width:222px;">支付金额：{{scope.row.PayMoney}}（含运费：{{scope.row.FreightMoney}}）</div>
									<div class="single">优惠券：{{scope.row.ActivityCouponMoney}}</div>
									<div class="single">满减送：{{scope.row.ActivityMarkdownGiftMoney}}</div>
									<div class="single">打包一口价：{{scope.row.ActivityBaleMoney}}</div>
									<div class="single">商品成本：{{scope.row.OrderProductCostMoney}}</div>
									<div class="single">收益与提成：{{scope.row.OrderCommissionAwardMoney}}</div>
									<div class="single">自定义成本：{{scope.row.OrderCustomCostMoney}}</div>
								</div>
							</div>

							<div class="dialog-name-content" v-else>
								<div class="right">
									<div class="name">{{scope.row.ProductName}}</div>
									<span>{{scope.row.ProductNo}}</span>
								</div>
							</div>

						</template>
					</el-table-column>
					<el-table-column prop="TypeName" label="规格"  min-width="250px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<div class="right">
									<div class="name">{{scope.row.SpecValueAll==''?'默认规格':scope.row.SpecValueAll}}</div>
									<span>{{scope.row.BarCode}}</span>
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="ProductCount" >
						<template slot="header" slot-scope="scope">
							数量
							<el-tooltip class="item" effect="dark" content="=商品购买数量-退款成功数量" placement="top">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</template>
					</el-table-column>

					<el-table-column prop="RealMoney">
						<template slot="header" slot-scope="scope">
							金额
							<el-tooltip class="item" effect="dark" content="=商品实付金额-退款成功金额" placement="top">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</template>
					</el-table-column>

					<el-table-column prop="CostPrice" label="成本/件"></el-table-column>

					<el-table-column prop="MemberCommissionAwardMoney"  label="备注" width='推手收益'>
						<template slot="header" slot-scope="scope">
							推手收益
							<el-tooltip class="item" effect="dark" content="不包含已扣除收益" placement="top">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="MallEmployeeCommissionAwardMoney" label="员工提成">
						<template slot="header" slot-scope="scope">
							员工提成
							<el-tooltip class="item" effect="dark" content="不包含已扣除提成" placement="top">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="ProductCashbackMoney" label="自定义成本" min-width="150">
						<template slot-scope="scope">
							<div>
								<div class="flexRow">
									<div v-if="scope.row.CustomCostList&&scope.row.CustomCostList.length">
										<div v-for="(x,y) in scope.row.CustomCostList" :key="y" style="font-zie:14px;color:#606266;line-height:1.5">
											{{x.CostName}}：{{x.CostMoney}}
											<buttonPermissions :datas="'grossMarginedit'">
												<el-button type="text" v-if="y==scope.row.CustomCostList.length-1"
													style="margin:0px;padding:0px;margin-left:10px" @click="editcustomcost(scope.row)">编辑</el-button>
											</buttonPermissions>
										</div>
									</div>
									<div v-else>
											<span>0</span>
											<buttonPermissions :datas="'grossMarginedit'">
												<el-button type="text"
													style="margin:0px;padding:0px;margin-left:10px" @click="editcustomcost(scope.row)">编辑</el-button>
											</buttonPermissions>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="毛利预算">
						<template slot="header" slot-scope="scope">
							毛利预算
							<el-tooltip class="item" effect="dark" content="=金额-数量*成本价-推手收益-员工提成-自定义成本" placement="top">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</template>

						<template slot-scope="scope">
							<span :style="{'color':Number(scope.row.GrossProfitMoney)>0?'#606266':'#F56C56'}">{{scope.row.GrossProfitMoney}}</span>
						</template>
					</el-table-column>
			</el-table>
			<div style="text-align:right;margin-top:10px;">
				<el-pagination v-if="Total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="Total">
				</el-pagination>
			</div>
		</div>

		<el-dialog :visible.sync="costShow" title="自定义成本" width="600px" custom-class="dialog-body-paddingTop-10" v-loading="costloading">
			<div style="max-height:500px;overflow-y:auto">
				<div class="constclass" v-for="(v,i) in costmsg.list" :key="i">
					<div class="graybox">
						<div class="flexRow" style="margin-bottom:20px">
							<div class="label">成本项：</div>
							<el-input v-model="v.CostName" placeholder="请输入成本项名称" maxlength="20"></el-input>
						</div>
						<div class="flexRow">
							<div class="label">金额：</div>
							<el-input placeholder="请输入成本金额" v-model="v.CostMoney"
							oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
						</div>
					</div>
					<el-button type="text" style="color:#f56c6c" @click="delcose(i)">删除成本项</el-button>
				</div>
				<el-button v-if="costmsg.list&&costmsg.list.length<10" @click="pushcost">添加成本项</el-button>
			</div>
			<div slot="footer">
				<el-button @click="costShow=false">取消</el-button>
				<el-button type="primary" @click="savecost">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				startTime:'',
				endTime:'',
				keyWord:'',
				SortType:0,
				moneyAmount:0,
				grossProfit: {
					moneyAmount: 0,
					NotSendOrderCount: 0,
					NotSendExpressCount: 0,
					SendOrderCount: 0,
					SendExpressCount: 0,
					RefundOrderCount: 0,
					RefundExpressCount: 0,
				},
				groupData: [],
				currentPage: 1,
				tableMaxHeight:window.innerHeight  - 380,
				pageSize: 20,
				Total: 1,
        searchKey: '',
				loading: false,
				exportUrl: config.EXPORT_URL,
				OrderBy : '',
				IsAsc : '',
				goUrls: config.GO_URL,
				rowmsg:{},
				costShow:false,
				costmsg:{
					list:[]
				},
				costloading:false,
			}
    },
    computed:{
			minOptions: function() {
				let limitTime = this.startTime
				let currentDate = '2020-05-20' + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let currentDate = '2020-05-20' + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
    },
		created() {
			let date = new Date();
			let year = date.getFullYear();
			let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
			let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
			this.startTime = year + '-' + month + '-' + day + ' ' + '00:00:00';
			this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';

			this.getList()
		},
		methods: {
			pushcost(){
				this.costmsg.list.push({
					CostName:'',
					CostMoney:'',
				})
			},
			async savecost(){
				for(let item of this.costmsg.list){
					if(!item.CostName){
						this.$message.error('请输入成本项名称')
						return
					}
					if(!item.CostMoney){
						this.$message.error('请输入成本金额')
						return
					}else if(item.CostMoney<0.01){
						this.$message.error('成本金额最小0.01')
						return
					}else if(item.CostMoney>999999){
						this.$message.error('成本金额过大，请重新输入')
						return
					}
				}

				try{
					this.costloading = true
					let res = await apiList.customCostedit({
						OrderNo:this.rowmsg.OrderNo,
						OrderDetailId:this.rowmsg.OrderDetailId,
						CustomCostList:this.costmsg.list
					})
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.costShow = false
						this.getList()
					}
				}finally{
					this.costloading = false
				}
			},
			delcose(i){
				this.costmsg.list.splice(i,1)
			},
			async editcustomcost(row){
				try{
					this.loading = true
					let res = await apiList.customCostinfo({
						OrderNo:row.OrderNo,
						OrderDetailId:row.OrderDetailId
					})
					if(res.IsSuccess){
						this.rowmsg = row
						this.costmsg = {
							list:res.Result.CustomCostList
						}
						this.costShow = true
					}
				}finally{
					this.loading = false
				}
			},
			//获取数据
			async getList() {
				this.loading = true
				try {
					let data = {
						KeyWords: this.keyWord,
						SortType: this.SortType,
						StartTime: this.startTime,
						EndTime:  this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize
					}

					let result = await apiList.grossProfitList(data)
					this.Total = result.Result.Total;

					this.grossProfit = {
						moneyAmount: result.Result.GrossProfitMoneyAll,
						NotSendOrderCount: result.Result.NotSendOrderCount,
						NotSendExpressCount: result.Result.NotSendExpressCount,
						SendOrderCount: result.Result.SendOrderCount,
						SendExpressCount: result.Result.SendExpressCount,
						RefundOrderCount: result.Result.RefundOrderCount,
						RefundExpressCount: result.Result.RefundExpressCount,
					}

					let list= result.Result.Results || [];
					
					this.groupData = []
					list.forEach((t)=>{
						let item = {
							type: 1,
							OrderNo: t.OrderNo,
							PayMoney: t.PayMoney,
							ActivityCouponMoney: t.ActivityCouponMoney,
							ActivityMarkdownGiftMoney: t.ActivityMarkdownGiftMoney,
							ActivityBaleMoney: t.ActivityBaleMoney,
							CashbackMoney: t.CashbackMoney,
							OrderGrossProfitMoney: t.OrderGrossProfitMoney,
							FreightMoney: t.FreightMoney,
							AddTime: t.AddTime,
							PayTime: t.PayTime,
							OrderState: t.OrderState,
							OrderStateValue: t.OrderStateValue,
							OrderType: t.OrderType,
							OrderTypeValue: t.OrderTypeValue,
							OrderProductCostMoney:t.OrderProductCostMoney,
							OrderCommissionAwardMoney:t.OrderCommissionAwardMoney,
							OrderCustomCostMoney:t.OrderCustomCostMoney,

						}
						this.groupData.push(item)
						t.OrderGrossProfitDetailList.forEach((tt)=>{
							let iitem= {
								...tt,
								type:0
							}
							this.groupData.push(iitem)
						})
					})
					
					
					// console.log(this.groupData,'表格的数据格式')
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			// 查看订单详情
			lookOrderDetails(record) {
				let OrderNoId = record.OrderNo ? record.OrderNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/order/orderDetailpage?Id=' + OrderNoId
				window.open(url)
			},
			arraySpanMethod({row,	column,rowIndex,columnIndex	}) {
				// console.log(row,'当前行的数据')
				if(row.type === 1){
					if (columnIndex === 0){
						return {
						  rowspan: 1,
						  colspan: 9
						};
					}else{
						return {
						  rowspan: 0,
						  colspan: 0
						};
					}
				}
			},
			setClass({row, rowIndex}){
				if (row.type === 1){
					return 'special-row'
				}
			},
			headerStyle(){
				return {
					background: '#F8F8F9FF'
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/order/grossProfit/list?' +
						'&StartTime=' + (this.startTime ? this.startTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime: '') +
						'&KeyWords=' + this.keyWord  +
						'&SortType=' + this.SortType 
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
.constclass{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 20px;
	.graybox{
		background: rgb(251,251,251);
		border: 1px solid rgb(241,241,241);
		padding: 15px;
		flex:1;
		margin-right: 15px;
		.label{
			font-size: 14px;
			color: #606266FF;
			flex-shrink: 0;
			white-space: nowrap;
			line-height: 36px;
			width: 80px;
			text-align: right;
			&::before{
				content: '*';
				color: #f56c6c;
			}
		}
	}
}
.flexRow{
	display: flex;
	align-items: center;
}
.filter-top{
  margin-bottom:10px;
	padding:5px 20px;
	line-height: 25px;
  background:#FDF6EC;;
  color:#FDA23C;
  border:1px solid #FDA23C;
  border-radius:2px;
	font-size: 14px;
}
.gross-infos {
	margin: 10px 0;
	background-color:#F8F8F9FF;
	line-height: 25px;
	padding: 5px 10px;
	font-size: 14px;
	.gross-detail {
		color:#909399;
		font-size: 12px;
		.gross-item{
			margin-right: 20px;
		}
	}
}
.dialog-name-content {
	display: flex;
	flex-direction: row;
	.name{
			width: 250px;
			overflow:hidden;
			text-overflow:ellipsis;
			display:-webkit-box;
			-webkit-line-clamp:2;
			-webkit-box-orient:vertical;
	}
	span {
		color: #909399;
		font-size: 12px;
	}
}
::v-deep .special-row{
	padding-top: 10px;
	
	td{
		padding: 10px 0 0 0 !important;
	}
	
	.cell{
		padding: 0;
	}
	
}

	.name-wraper{
		background: #409EFF1A !important;
		padding:5px 10px;
		.line{ 
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			.single{
				line-height: 30px;
				width: 200px;
				margin-right: 20px;
			}
		}
	}
</style>
